import { useContext, useEffect } from 'react';
import useOnScreen from './useOnScreen';
import AppContext from "../context/AppContext";

function useTheme( ref, theme ) {
    const { dispatch } = useContext( AppContext );
    const isVisible = useOnScreen(ref, '-75% 0px -25% 0px');

    useEffect(() => {
        if( isVisible ) {
            dispatch({ type: 'SET_THEME', payload: theme})
        }
    }, [isVisible, theme, dispatch]);
}

export default useTheme;