import * as React from "react"
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Section, { SectionBody } from "../components/Section/Section";
import HtmlContent from "../components/HtmlContent";

const GenericPage = ({data: { page }}) => {
  return (
    <Layout theme="secondary" title={page.data.meta_title || page.data.heading.text} description={page.data.meta_description}>
      <Section theme="secondary">
        <SectionBody>
          <h1>{page.data.heading.text}</h1>
          <HtmlContent content={page.data.body.html}/>
        </SectionBody>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query GenericQuery($id: String) {
    page: prismicGeneric(id: { eq: $id }) {
      data {
        meta_title
        meta_description
        heading {
          text
        }
        body {
          html
        }
      }
    }
  }
`

export default GenericPage
