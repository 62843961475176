import React, { useRef } from 'react';
import classNames from 'classnames';
import useTheme from '../../hooks/useTheme';
import './Section.scss';

function Section({children, theme}) {
    const ref = useRef(null);
    
    useTheme( ref, theme );

    return (
        <section className="Section" ref={ref}>
            <div className="Section__inner">
                {children}
            </div>
        </section>
    );
}

function SectionIntro({ heading, lead }) {
    return (
        <div className="SectionIntro">
            <h2 className="SectionIntro__heading">{heading}</h2>
            <p className="SectionIntro__lead">{lead}</p>
        </div>
    );
}

function SectionBody({children, isColumned}) {
    const className = classNames(`SectionBody`, {
        [`has-columns`]: isColumned
    });

    return (
        <div className={className}>
            {children}
        </div>
    );
}

export { Section as default, SectionIntro, SectionBody };